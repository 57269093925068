var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-select":""},scopedSlots:_vm._u([{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.last_name)+" ")])]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.first_name)+" ")])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.gender)+" ")])]}},{key:"item.individual_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.individual_id)+" ")])]}},{key:"item.mobile_phone_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.mobile_phone_number)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.membership_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.membership_type)+" ")])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.start_date)+" ")])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-color-main caption"},[_vm._v(" "+_vm._s(item.end_date)+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.show(item.id)}}},[_vm._v("mdi-dots-horizontal")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }