<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    item-key="id"
    class="rounded-lg"
    show-select
  >
    <template v-slot:[`item.last_name`]="{ item }">
      <span class="text-color-main caption">
        {{ item.last_name }}
      </span>
    </template>
    <template v-slot:[`item.first_name`]="{ item }">
      <span class="text-color-main caption">
        {{ item.first_name }}
      </span>
    </template>
    <template v-slot:[`item.gender`]="{ item }">
      <span class="text-color-main caption">
        {{ item.gender }}
      </span>
    </template>
    <template v-slot:[`item.individual_id`]="{ item }">
      <span class="text-color-main caption">
        {{ item.individual_id }}
      </span>
    </template>
    <template v-slot:[`item.mobile_phone_number`]="{ item }">
      <span class="text-color-main caption">
        {{ item.mobile_phone_number }}
      </span>
    </template>
    <template v-slot:[`item.email`]="{ item }">
      <span class="text-color-main caption">
        {{ item.email }}
      </span>
    </template>
    <template v-slot:[`item.membership_type`]="{ item }">
      <span class="text-color-main caption">
        {{ item.membership_type }}
      </span>
    </template>
    <template v-slot:[`item.start_date`]="{ item }">
      <span class="text-color-main caption">
        {{ item.start_date }}
      </span>
    </template>
    <template v-slot:[`item.end_date`]="{ item }">
      <span class="text-color-main caption">
        {{ item.end_date }}
      </span>
    </template>
    <template v-slot:[`item.details`]="{ item }">
      <v-icon @click="show(item.id)">mdi-dots-horizontal</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { PEOPLE_DETAILS_DRAWER_EVENT_BUS } from "../drawers/PeopleDetailsDrawerEventBus.js";
// const PeopleDetailsDrawer = () => import("../drawers/PeopleDetailsDrawer");

export default {
  components: {
    // PeopleDetailsDrawer
  },
  data: () => ({
    headers: [
      {
        text: "Last Name",
        value: "last_name",
        sortable: false
      },
      {
        text: "First Name",
        value: "first_name",
        sortable: false
      },
      {
        text: "Gender",
        value: "gender",
        sortable: false
      },
      {
        text: "Individual ID",
        value: "individual_id",
        sortable: false
      },
      {
        text: "Mobile Phone Number",
        value: "mobile_phone_number",
        sortable: false
      },
      {
        text: "Email",
        value: "email",
        sortable: false
      },
      {
        text: "Membership Type",
        value: "membership_type",
        sortable: false
      },
      {
        text: "Start Date",
        value: "start_date",
        sortable: false
      },
      {
        text: "End Date",
        value: "end_date",
        sortable: false
      },
      {
        text: "",
        value: "details",
        sortable: false
      }
    ],
    items: [],
    selected: []
  }),
  watch: {
    selected: function(value) {
      console.log({ value });
      this.$emit("setSelected", value);
    }
  },
  methods: {
    getData() {
      this.items = [
        {
          id: 1,
          last_name: "Lorem",
          first_name: "Ipsum",
          gender: "Male",
          individual_id: "112143QWE",
          mobile_phone_number: "1266897866",
          email: "fourello@dev.com",
          membership_type: "Member",
          start_date: new Date().toISOString().substr(0, 10),
          end_date: new Date().toISOString().substr(0, 10)
        },
        {
          id: 2,
          last_name: "Lorem",
          first_name: "Ipsum",
          gender: "Male",
          individual_id: "112143QWE",
          mobile_phone_number: "1266897866",
          email: "fourello@dev.com",
          membership_type: "Member",
          start_date: new Date().toISOString().substr(0, 10),
          end_date: ""
        },
        {
          id: 3,
          last_name: "Lorem",
          first_name: "Ipsum",
          gender: "Male",
          individual_id: "112143QWE",
          mobile_phone_number: "1266897866",
          email: "fourello@dev.com",
          membership_type: "Member",
          start_date: new Date().toISOString().substr(0, 10),
          end_date: ""
        },
        {
          id: 4,
          last_name: "Lorem",
          first_name: "Ipsum",
          gender: "Male",
          individual_id: "112143QWE",
          mobile_phone_number: "1266897866",
          email: "fourello@dev.com",
          membership_type: "Member",
          start_date: new Date().toISOString().substr(0, 10),
          end_date: ""
        },
        {
          id: 5,
          last_name: "Lorem",
          first_name: "Ipsum",
          gender: "Male",
          individual_id: "112143QWE",
          mobile_phone_number: "1266897866",
          email: "fourello@dev.com",
          membership_type: "Member",
          start_date: new Date().toISOString().substr(0, 10),
          end_date: ""
        }
      ];
    },
    show(id) {
      console.log({ id });
      PEOPLE_DETAILS_DRAWER_EVENT_BUS.$emit("showPeopleDetailsDrawer", id);
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
